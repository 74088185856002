<template>
  <lf-modal ref="modal" :close="close" title="" small>
    <template #content>
      <div class="p-6">
        <div
          class="flex flex-col flex-nowrap items-center justify-center space-y-4"
        >
          <img src="/images/exitIllustration.svg" loading="lazy" />
          <lf-h3 class="">{{ $t("ORGANIZATION.TEST_MODE.EXIT_TITLE") }}</lf-h3>
          <p class="">{{ $t("ORGANIZATION.TEST_MODE.EXIT_DESCRIPTION") }}</p>
          <primary-button
            class="w-full"
            @click="exitTestMode"
            :disabled="isSubmitting"
            role="test_mode_exit_button"
          >
            {{ $t("ORGANIZATION.TEST_MODE.EXIT_BUTTON_CONFIRM_TEXT") }}
          </primary-button>
          <outline-button class="w-full" @click="close">
            {{ $t("ORGANIZATION.TEST_MODE.EXIT_BUTTON_CANCEL_TEXT") }}
          </outline-button>
        </div>
      </div>
    </template>
  </lf-modal>
</template>

<script setup lang="ts">
import LfModal from "@/components/ui/Modal.vue";

import type { PropType } from "vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useAuth } from "@/hooks/auth";
import OutlineButton from "@/components/ui/buttons/OutlineButton.vue";
import type { IUser } from "@/models/users";
const modal = ref(null);
const { isSuperAdmin, isClient } = useAuth();
const { dispatch, getters } = useStore();
const isSubmitting = ref(false);
const user = computed<IUser>(() => getters["auth/user"]);

const props = defineProps({
  close: {
    type: Function as PropType<() => void>,
    required: true
  }
});

const exitTestMode = async () => {
  if (!isSuperAdmin && !isClient) {
    return;
  }
  isSubmitting.value = true;
  await dispatch("clients/updateClientTestMode", {
    id: user.value.client_id,
    test_mode: false
  });
  await dispatch("auth/getAuthClientSettings");
  props.close();
  isSubmitting.value = false;
};
</script>
